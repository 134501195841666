export const jobsList = [
  {
    title: "Isolated Room",
    img: "./assets/isolated.jpg",
    description: "ISOLATED_DESCRIPTION",
    url: "https://store.steampowered.com/app/2219520/Isolated_Room/",
  },
  {
    title: "XCOW - Leave The Humans, Take The Cows!",
    img: "./assets/xcow.png",
    description: "XCOW_DESCRIPTION",
    url: "https://gamejolt.com/games/xcow/285727",
  },
  {
    title: "One Man Crew",
    img: "./assets/omc.png",
    description: "OMC_DESCRIPTION",
    url: "https://luhoff.itch.io/one-man-crew",
  },
];
