import React from "react";
import { useTranslation } from "react-i18next";
import { jobsList } from "../../jobsList";
import "./Jobs.scss";
function Jobs() {
  const { t } = useTranslation();
  return (
    <div className="jobs">
      <h1 className="jobsTitle">{t("JOBS_TITLE")}</h1>
      {jobsList.map((job) => (
        <div className="job">
          <a href={job.url} target="blank">
            <img src={job.img} alt={job.title} />
          </a>
          <div className="job__text">
            <h1>
              <a href={job.url} target="blank">
                {job.title}
              </a>
            </h1>
            <p>{t(job.description)}</p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Jobs;
