import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const preferredLanguage = navigator.language;
const resources = {
  "en-US": {
    translation: {
      WHO_AM_I: "Who am I?",
      WHO_TEXT:
        "My name is Lucas Hoffmann, I’m CURRENT_AGE years old and I work as a web developer and freelance music composer.\n\n I have started my music career CAREER_AGE years ago, working on soundtracks for game jams projects.\n\n In 2020, I released a fully self-produced album on the Internet, called Always Blue. As for now, I play guitar, bass and keyboard (I love learning new instruments). In my projects I mostly use virtual instruments. I can also emulate chiptunes from retro consoles, such as the NES, SNES and SEGA Genesis.\n\n I deliver my songs in loopable audio files. I'm yet to learn how to use FMOD.\n\n Feel free to contact me in case you need a composer for your project!",
      ICONS_CREDITS:
        "Icons by Freepik, Jesus Chavarria and Icon Hubs. All rights reserved.",
      SOUNDTRACK: "My VGM",
      MY_ALBUM: "My Album",
      JOBS_TITLE: "Some games I worked on",
      ISOLATED_DESCRIPTION:
        "In this game about isolation and dealing with work from home, I worked on all the songs and sound effects (except on an minigame which features another composer).\n The soundtrack for this game is very moody and somewhat melhancolic, as it takes place in a day of the life of a person with anxiety and depression.\n It won 4th place on the AKON Quarentine Game Jam 2020.\n In 2022 it was updated and released on Steam.",
      XCOW_DESCRIPTION:
        'Have you ever dreamed of being a farmer saving your cattle from alien abductors? Than this game is for you! \n In XCOW, you must take your cows to safety in the barn while you shoot space people trying to get some free Filet Mignon. \n I worked on the X-files, Doctor Who and Deus Ex inspired soundtrack. It won 1st place on the category Polishment in the Brazilian game Jam "365 Indies Jam 2017". ',
      OMC_DESCRIPTION:
        'What do you do when your game jam partners have life obligations and must quit the project one day before the deadline with nothing done? You finish the game yourself, of course! \n For this project I had the music done, but nothing else, so I learned the Construct 2 engine and made a pretty rough and buggy space "infinite runner", with a twist, in around 6 hours. I left it untouched because of its curious history, but I\'d like to revisit the gameplay mechanics in the future, maybe in a new game engine.',
    },
  },
  "pt-BR": {
    translation: {
      WHO_AM_I: "Quem sou eu?",
      WHO_TEXT:
        "Me chamo Lucas Hoffmann, tenho CURRENT_AGE anos e trabalho como desenolvedor web e como compositor freelancer. \n\n Comecei minha carreira na música CAREER_AGE anos atrás, trabalhando na trilha sonora para pequenos projetos e em game jams. \n\n Em 2020, lancei na Internet um álbum inteiramente produzindo por mim, chamado Always Blue. \n\n Atualmente, toco violão, guitarra, baixo e teclado (eu amo aprender novos instrumentos). Para os projetos que trabalho, utilizo majoritariamente instrumentos virtuais. Também simulo músicas de consoles retro como o NES, SNES e Mega-drive. \n\n Eu entrego meus trabalhos em arquivos de áudio em loop. Estou procurando uma oportunidade para aprender a usar o FMOD. \n\n Sinta-se livre para entrar em contato comigo caso necessite de um compositor para seu projeto!",
      ICONS_CREDITS:
        "Ícones por Freepik, Jesus Chavarria e Icon Hubs. Todos os direitos reservados.",
      SOUNDTRACK: "Minhas trilhas sonoras",
      MY_ALBUM: "Meu álbum",
      JOBS_TITLE: "Alguns jogos em que trabalhei",
      ISOLATED_DESCRIPTION:
        "Neste jogo sobre lidar com trabalho de casa e isolamento, eu trabalhei em todas as músicas e efeitos sonoros (exceto em um minigame, que contém outro compositor). \n A trilha sonora para esse jogo é bem sentimental e um tanto melancólica, uma vez que o jogo se passa em um dia na vida de uma pessoa com anxiedade e depressão. \n Ganhou o quarto lugar na AKON Quarentine Game Jam 2020. \n Em 2022 foi atualizado com novas músicas e funcionalidades e lançado na Steam.",
      XCOW_DESCRIPTION:
        'Já sonhou em ser um fazendeiro salvando seu gado de sequestradores alienígenas? Então esse jogo é para você! \n Em XCOW, você precisa levar suas vacas para a segurança no celeiro, enquanto atira em seres do espaço que estão tentando conseguir um Filé Mignon gratuito. \n Eu trabalhei nesta trilha sonora inspirada em X-files, Doctor Who e Deus Ex. Ganhou o primeiro lugar na categoria Acabamento na game jam brasileira "365 Indies Jam 2017". ',
      OMC_DESCRIPTION:
        'O que fazer quando seus parceiros de game jam têm obrigações de vida e precisam abandonar o projeto um dia antes do prazo com nada feito? Você termina o jogo sozinho, é claro! \n Para este projeto, eu já tinha a música pronta, mas nada mais, então aprendi a engine Construct 2 e fiz um "infinite runner" espacial, com uma pegada, bem rústico e bugado em cerca de 6 horas. Deixei este projeto inalterado devido à sua curiosa história, mas gostaria de revisitar as mecânicas de jogo no futuro, talvez em uma nova engine.',
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: preferredLanguage, // if you're using a language detector, do not define the lng option
    fallbackLng: "en-US", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
