import { useEffect, useState } from "react";
import { useProSidebar } from "react-pro-sidebar";
import "./App.scss";
import Main from "./components/main/Main";
import SideBar from "./components/sideBar/SideBar";

function App() {
  const [darkMode, setDarkMode] = useState(
    window.localStorage.getItem("darkMode")
      ? JSON.parse(window.localStorage.getItem("darkMode"))
      : true
  );
  const { collapsed } = useProSidebar();

  useEffect(() => {
    localStorage.setItem("darkMode", darkMode);
  }, [darkMode]);

  return (
    <div
      className={
        "App" + (darkMode ? " dark" : " light") + (collapsed ? "" : " menuOpen")
      }
    >
      <SideBar setDarkMode={setDarkMode} />
      <Main />
    </div>
  );
}

export default App;
