export const playerList = [
  {
    title: "SOUNDTRACK",
    src: `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1573873036&color=%234d4c7d&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true`,
  },
  {
    title: "MY_ALBUM",
    src: `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1167589000&color=%234d4c7d&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true`,
  },
];
