import React from "react";
import { useTranslation } from "react-i18next";
import { playerList } from "../../playerList";
import Jobs from "../jobs/Jobs";
import Player from "../player/player";
// import { Container } from './styles';

function Main() {
  const currentYear = new Date().getFullYear();
  const { t } = useTranslation();
  return (
    <div className="Main">
      <div className="players">
        {playerList.map((player) => (
          <Player src={player.src} title={player.title} />
        ))}
      </div>
      <Jobs />
      <footer>
        {t("ICONS_CREDITS")} {currentYear}
      </footer>
    </div>
  );
}

export default Main;
