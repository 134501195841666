import React from "react";
import { Sidebar, useProSidebar } from "react-pro-sidebar";
import useMediaQuery from "../../hooks/useMediaQuery";
import "./SideBar.scss";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "i18next";

const SideBarContent = ({ setDarkMode, isDesktop }) => {
  const { t } = useTranslation();
  const { collapseSidebar } = useProSidebar();
  const whoAmIText = t("WHO_TEXT")
    .replace("CURRENT_AGE", new Date().getFullYear() - 1999)
    .replace("CAREER_AGE", new Date().getFullYear() - 2014);
  React.useEffect(() => {
    console.log(isDesktop);
  }, [isDesktop]);
  return (
    <div className="SideBarContent">
      <div className="profile">
        <img src="./assets/profile.jpg" alt="profile" />
      </div>

      <div className="SideBarText">
        <h2>{t("WHO_AM_I")}</h2>
        <p>{whoAmIText}</p>
        <br />
        <p>Email: lucashoffmann99@gmail.com</p>
      </div>

      <div className="icons">
        <img
          src="./assets/usa.png"
          alt="Switch to English"
          onClick={() => changeLanguage("en-US")}
        />
        <img
          src="./assets/brazil.png"
          alt="Switch to Portuguese"
          onClick={() => changeLanguage("pt-BR")}
        />
        <img
          src="./assets/dark.png"
          alt="Toggle Dark Mode"
          className="icon"
          onClick={() => setDarkMode((prev) => !prev)}
        />
        {!isDesktop && (
          <img
            src="./assets/back.png"
            alt="Close side menu"
            className="icon"
            onClick={() => collapseSidebar()}
          />
        )}
      </div>
    </div>
  );
};

function SideBar({ ...props }) {
  const isDesktop = useMediaQuery(`(min-width: 800px)`);

  const { collapseSidebar, collapsed } = useProSidebar();
  return (
    <>
      <Sidebar
        width={isDesktop ? "22vw" : "100vw"}
        defaultCollapsed={!isDesktop}
        collapsedWidth={0}
      >
        <SideBarContent {...props} isDesktop={isDesktop} />
      </Sidebar>
      {collapsed && (
        <img
          src="./assets/menu.png"
          alt="Open menu"
          className="menuButton icon"
          onClick={() => collapseSidebar()}
        />
      )}
    </>
  );
}

export default SideBar;
