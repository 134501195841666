import React from "react";
import { useTranslation } from "react-i18next";

// import { Container } from './styles';

function Player({ title, src }) {
  const { t } = useTranslation();

  const titleTranslated = t(title);
  return (
    <div className="player">
      <h1>{titleTranslated}</h1>
      <iframe
        title={titleTranslated}
        width="100%"
        height="450"
        src={src}
      ></iframe>
    </div>
  );
}

export default Player;
